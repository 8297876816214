import * as _froalaEditor2 from "froala-editor";

var _froalaEditor = "default" in _froalaEditor2 ? _froalaEditor2.default : _froalaEditor2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*!
 * froala_editor v4.0.15 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2022 Froala Labs
 */
!function (e, t) {
  t(_froalaEditor);
}(exports, function (e) {
  "use strict";

  e = e && e.hasOwnProperty("default") ? e["default"] : e, Object.assign(e.DEFAULTS, {
    inlineStyles: {
      "Big Red": "font-size: 20px; color: red;",
      "Small Blue": "font-size: 14px; color: blue;"
    }
  }), e.PLUGINS.inlineStyle = function (i) {
    return {
      apply: function a(e) {
        for (var t = e.split(";"), n = 0; n < t.length; n++) {
          var l = t[n].split(":");
          t[n].length && 2 == l.length && i.format.applyStyle(l[0].trim(), l[1].trim());
        }
      }
    };
  }, e.RegisterCommand("inlineStyle", {
    type: "dropdown",
    html: function () {
      var e = "<ul class=\"fr-dropdown-list\" role=\"presentation\">",
          t = (this || _global).opts.inlineStyles;

      for (var n in t) if (t.hasOwnProperty(n)) {
        var l = t[n] + (-1 === t[n].indexOf("display:block;") ? " display:block;" : "");
        e += "<li role=\"presentation\"><span style=\"".concat(l, "\" role=\"presentation\"><a class=\"fr-command\" tabIndex=\"-1\" role=\"option\" data-cmd=\"inlineStyle\" data-param1=\"").concat(t[n], "\" title=\"").concat((this || _global).language.translate(n), "\">").concat((this || _global).language.translate(n), "</a></span></li>");
      }

      return e += "</ul>";
    },
    title: "Inline Style",
    callback: function (e, t) {
      (this || _global).inlineStyle.apply(t);
    },
    plugin: "inlineStyle"
  }), e.DefineIcon("inlineStyle", {
    NAME: "paint-brush",
    SVG_KEY: "inlineStyle"
  });
});
export default exports;